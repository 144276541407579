.page-container {
  position: relative;
  min-height: 100vh;
  background-color: white;
  padding-top: 100px;
}

.text-not-found {
  color: #ee7325;
  font-weight: 900;
}

.spinner {
  height: 3rem;
  width: 3rem;
}

.logo {
  transform: scale(0.9);
}

.logo-div {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@media all and (max-width: 420px) {
  .logo-div {
    text-align: center;
    padding-top: 30px;
  }
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__container #react-autowhatever-1 {
  position: absolute;
  width: 100%;
  background-color: #fff;
}