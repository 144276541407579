.pageContainer {
    position: relative;
    min-height: 100vh;
    background-color: white;
    padding-top: 100px;
  }
  
  .textNotFound {
    color: #ee7325;
    font-weight: 900;
  }
  
  .spinner {
    height: 3rem;
    width: 3rem;
  }
  
  .logo {
    transform: scale(0.9);
  }
  
  .logoDiv {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  @media all and (max-width: 420px) {
    .logoDiv {
      text-align: center;
      padding-top: 30px;
    }
  }
  