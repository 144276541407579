body {
  background-color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
}
.wrapper {
  max-width: 765px;
}
input[type="radio"] {
  cursor: pointer;
}
.gray {
  color: #868e9d;
}
.red {
  color: red;
}
.customTextbox,
.custom-dropdown {
  border-radius: 0;
}
.custom-dropdown {
  appearance: menulist-button;
}
.custom-button {
  background-color: #e2001a;
  border-radius: 0;
  border: 0;
  color: #fff;
  padding: 7px 10px 7px 10px;
}
.custom-button:disabled {
  background-color: darkgray;
}
.pdf-icon {
  margin-top: -3px;
  width: 50px;
  height: 40px;
}
.pdf-button {
  display: inline-block;
  max-width: 300px;
}
/* person function suggestion autocomplete*/
.react-autosuggest__suggestions-list {
  list-style-type: none;
  padding: 6px 0 5px 0;
  margin: 0;
  border: 1px solid #ced4da;
  border-radius: 4px;
}
.react-autosuggest__suggestion {
  padding: 0 5px 0 5px;
  cursor: pointer;
}
.react-autosuggest__input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0 !important;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.react-autosuggest__input:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.react-autosuggest-div {
  padding: 5px;
}
.react-autosuggest-div:hover {
  background-color: #e0e0e6;
  border-radius: 2px;
}